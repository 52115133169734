<script setup>
	// Composables
	import { getEnv } from '@/composables/helpers/getEnv';

	const { logo, attributes } = defineProps({
		logo: {
			type: Object,
			required: true,
		},
		info: {
			type: Object,
			required: true,
		},
		donate: {
			type: Object,
			required: true,
		},
		absoluteLinks: {
			type: Boolean,
			default: false,
		},
	});

	const clientFrontEnd = getEnv('clientFrontEnd');
</script>
<template>
	<section class="white-banner">
		<div class="container tablet-col-3">
			<div class="logo-with-contact">
				<div class="wrapper">
					<MessLink :href="absoluteLinks ? clientFrontEnd : '/'" class="footer-logo" :forceExternalLink="absoluteLinks">
						<MessImage :image="logo" />
					</MessLink>
					<div class="contact">
						<p v-if="info?.address" v-html="info.address" class="address"></p>
						<p v-if="info?.phoneNumber" v-html="info.phoneNumber"></p>
					</div>
				</div>
			</div>

			<div class="center-hours">
				<h2 class="h2">Center Hours</h2>
				<FragmentsHours :showSpecialClosures="false" />
			</div>

			<div class="donate">
				<div>
					<h2 v-if="donate?.headline" v-html="donate.headline" class="h2"></h2>
					<p v-if="donate?.copy" v-html="donate.copy" class="copy"></p>
				</div>
				<ProjectButton v-if="donate?.linkUrl" :link="donate.linkUrl" :label="donate.linkText" class="red" />
			</div>
		</div>
	</section>
</template>

<style lang="scss">
	.global-footer {
		.white-banner {
			margin: 1.5rem 0 3rem;

			// .tablet-col-3 {
			// 	@media (min-width: $mobile) {
			// 		display: grid;
			// 		grid-template-columns: repeat(12, calc(8.33% - 20px));
			// 		grid-template-rows: auto auto;
			// 		grid-gap: 20px;

			// 		> div:nth-of-type(1) {
			// 			grid-column: 1 / span 12;
			// 			grid-row: 1 / 1;
			// 		}

			// 		> div:nth-of-type(2) {
			// 			grid-column: 1 / span 6;
			// 			grid-row: 2;
			// 		}

			// 		> div:nth-of-type(3) {
			// 			grid-column: 7 / span 6;
			// 			grid-row: 2;
			// 		}
			// 	}

			// 	@media (min-width: $tablet) {
			// 		display: grid;
			// 		grid-template-columns: repeat(12, calc(8.33% - 20px));
			// 		grid-template-rows: auto;
			// 		grid-gap: 20px;

			// 		> div:nth-of-type(1) {
			// 			grid-column: 1 / span 4;
			// 			grid-row: 1 / 1;
			// 		}

			// 		> div:nth-of-type(2) {
			// 			grid-column: 5 / span 4;
			// 			grid-row: 1;
			// 		}

			// 		> div:nth-of-type(3) {
			// 			grid-column: 9 / span 4;
			// 			grid-row: 1;
			// 		}
			// 	}
			// }

			.tablet-col-3 {
				display: flex;
				gap: 1.5rem;
				align-items: stretch;
				> div {
					flex: 1;
				}

				@media (max-width: $tablet) {
					flex-direction: column;

					> div {
						width: 100%;
					}
				}
			}

			.logo-with-contact {
				padding: 1rem 1.5rem;
				background-color: var(--white);
				display: flex;

				@media (min-width: $mobile) {
					margin-bottom: 0;
				}

				.footer-logo {
					display: flex;
					margin-bottom: 12px;
					flex-direction: column;
					.mess-image {
						flex-shrink: 0;
					}

					@media (min-width: $desktop) {
						margin-bottom: 0;
					}

					.mess-image img,
					svg {
						max-width: 350px;
						width: 100%;
						object-fit: contain;
						background-color: var(--white);
					}
				}

				.contact {
					max-width: 390px;
					font-size: calc(14 / var(--base-fs) * 1rem);
					font-weight: 900;

					@media (min-width: $mobile) {
						display: flex;
						justify-content: space-between;
					}

					.address {
						margin-right: 10px;
					}
				}
			}

			.center-hours {
				padding: 1rem 1.5rem;
				background-color: var(--white);

				@media (min-width: $mobile) {
					margin-bottom: 0;
				}

				.h2 {
					margin-bottom: 15px;
				}

				.dates-groups {
					justify-content: space-between;
					flex-wrap: wrap;

					@media (min-width: $mobile) {
						display: flex;
					}

					.dates-with-hours {
						display: flex;
						align-items: flex-end;

						&:nth-of-type(odd) {
							margin-right: 15px;
						}

						p {
							font-size: calc(14 / var(--base-fs) * 1rem);
							letter-spacing: 1.575px;
							line-height: 1.5;

							&:nth-of-type(odd) {
								margin-right: 10px;
							}
						}
					}
				}
			}

			.donate {
				display: flex;
				align-items: center;
				padding: 1rem 1.5rem;
				background-color: var(--white);

				.copy {
					margin-right: 10px;
					font-size: calc(16 / var(--base-fs) * 1rem);

					@media (min-width: $tablet) {
						font-size: calc(14 / var(--base-fs) * 1rem);
					}
				}
			}
		}
	}
</style>
